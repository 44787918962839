<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-1">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter by Transaction Type</label>
            <b-select
              v-model="filter.transaction_type"
              :options="filterTransactionTypes"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="text-left"
        >
          <div class="form-group">
            <b-select
              v-model="selectMode"
              :options="selectionModes"
              class="mt-2 w-25"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="text-right"
        >
          <b-dropdown
            id="dropdown-1"
            text="Select Action"
            class="mt-2"
            variant="outline-secondary"
            :disabled="!hasSelectedRow"
          >
            <b-dropdown-item
              v-if="!isMultipleSelection"
              @click="onShowViewForm"
            >
              Process Transaction
            </b-dropdown-item>

            <b-dropdown-item
              v-if="isMultipleSelection"
              @click="clearSelected"
            >
              Clear All Selected
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isMultipleSelection"
              @click="onConfirmTaggingAll"
            >
              Tag All Selected As Completed
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
        :select-mode="selectMode"
        selectable
        @row-selected="onRowSelected"
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- modal -->
    <b-modal
      id="modal-view-form"
      scrollable
      no-close-on-backdrop
      title="Process Transaction"
    >
      <b-card no-body>
        <form
          novalidate
          role="form"
        >
          <b-tabs>
            <b-tab
              title="Request Details"
              active
            >
              <b-form-group>
                <label for="request_date">Request Date</label>
                <b-input
                  id="request_date"
                  v-model="paymentRequest.request_date"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="request_status">Request Status</label>
                <b-input
                  id="request_status"
                  v-model="paymentRequest.request_status"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="request_payee">Request Payee</label>
                <b-input
                  id="request_payee"
                  v-model="paymentRequest.request_payee"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="amount">Request Amount</label>
                <b-input
                  id="request_amount"
                  v-model="paymentRequest.request_amount"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="budget_name">Budget Name</label>
                <b-input
                  id="budget_name"
                  v-model="paymentRequest.budget_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="budget_actual_name">Budget Actual</label>
                <b-input
                  id="budget_actual_name"
                  v-model="paymentRequest.budget_actual_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="item_name">Item Description</label>
                <b-input
                  id="item_name"
                  v-model="paymentRequest.item_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="request_description">Request Description</label>
                <b-form-textarea
                  id="request_description"
                  v-model="paymentRequest.request_description"
                  rows="6"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="payment_mode">Payment Mode</label>
                <b-input
                  id="payment_mode"
                  v-model="paymentRequest.payment_mode"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.payment_mode === 'Check' || paymentRequest.payment_mode === 'Cash'">
                <label for="payment_action">Payment Action</label>
                <b-input
                  id="payment_action"
                  v-model="paymentRequest.payment_action"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.payment_action === 'Deposit' || paymentRequest.payment_mode === 'Online Banking'">
                <label for="bank_account_number">Bank Account Number</label>
                <b-input
                  id="bank_account_number"
                  v-model="paymentRequest.bank_account_number"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.payment_action === 'Deposit' || paymentRequest.payment_mode === 'Online Banking'">
                <label for="bank_account_name">Bank Account Name</label>
                <b-input
                  id="bank_account_name"
                  v-model="paymentRequest.bank_account_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.payment_action === 'Deposit' || paymentRequest.payment_mode === 'Online Banking'">
                <label for="bank">Bank</label>
                <b-input
                  id="bank"
                  v-model="paymentRequest.bank"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.approval_one_name !== null">
                <label for="approval_one_name">Noted By</label>
                <b-input
                  id="approval_one_name"
                  v-model="paymentRequest.approval_one_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.approval_one_name !== null">
                <label for="approval_one_at">Noted At</label>
                <b-input
                  id="approval_one_at"
                  v-model="paymentRequest.approval_one_at"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.approval_one_name !== null">
                <label for="approval_one_remarks">Noted By Remarks</label>
                <b-textarea
                  id="approval_one_remarks"
                  v-model="paymentRequest.approval_one_remarks"
                  rows="6"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.approval_two_name !== null">
                <label for="approval_two_name">Approved By</label>
                <b-input
                  id="approval_two_name"
                  v-model="paymentRequest.approval_two_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.approval_two_name !== null">
                <label for="approval_two_at">Approved At</label>
                <b-input
                  id="approval_two_at"
                  v-model="paymentRequest.approval_two_at"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.approval_two_name !== null">
                <label for="approval_two_remarks">Approved By Remarks</label>
                <b-textarea
                  id="approval_two_remarks"
                  v-model="paymentRequest.approval_two_remarks"
                  rows="6"
                  :disabled="true"
                />
              </b-form-group>

            </b-tab>

            <b-tab
              title="Tax Details"
            >
              <b-form-group>
                <label for="transaction_type">Transaction Type</label>
                <b-input
                  id="transaction_type"
                  v-model="paymentRequest.transaction_type"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                <label for="payment_nature">Payment Nature</label>
                <b-input
                  id="payment_nature"
                  v-model="paymentRequest.payment_nature"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                <label for="document_type">Document Type</label>
                <b-input
                  id="document_type"
                  v-model="paymentRequest.document_type"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                <label for="receipt_type">Receipt Type</label>
                <b-input
                  id="receipt_type"
                  v-model="paymentRequest.receipt_type"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                <label for="period_from">Period From</label>
                <b-input
                  id="period_from"
                  v-model="paymentRequest.period_from"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                <label for="period_to">Period To</label>
                <b-input
                  id="period_to"
                  v-model="paymentRequest.period_to"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                <label for="atc">Tax Code</label>
                <b-input
                  id="atc"
                  v-model="paymentRequest.atc"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                <label for="atc_description">Tax Description</label>
                <b-input
                  id="atc_description"
                  v-model="paymentRequest.atc_description"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                <label for="atc_payment">Tax Payment</label>
                <b-input
                  id="atc_payment"
                  v-model="paymentRequest.atc_payment"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                <label for="document_number">Document Number</label>
                <b-input
                  id="document_number"
                  v-model="paymentRequest.document_number"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="invoice_amount">Invoice Amount</label>
                <b-input
                  id="invoice_amount"
                  v-model="paymentRequest.invoice_amount"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="amount">Amount (Exclusive of VAT)</label>
                <b-input
                  id="amount"
                  v-model="paymentRequest.amount"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="tax_with_held">Tax Withheld</label>
                <b-input
                  id="tax_with_held"
                  v-model="paymentRequest.tax_with_held"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="pay_amount">Amount to Pay</label>
                <b-input
                  id="pay_amount"
                  v-model="paymentRequest.pay_amount"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.supplier_type !== null && paymentRequest.transaction_type === 'With 2307'">
                <label for="supplier_type">Supplier Type</label>
                <b-input
                  id="supplier_type"
                  v-model="paymentRequest.supplier_type"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.supplier_type !== null && paymentRequest.transaction_type === 'With 2307'">
                <label for="trade_name">Supplier Trade Name</label>
                <b-input
                  id="trade_name"
                  v-model="paymentRequest.trade_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.supplier_type === 'Corporation' && paymentRequest.transaction_type === 'With 2307'">
                <label for="company_name">Supplier Company Name</label>
                <b-input
                  id="company_name"
                  v-model="paymentRequest.company_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.supplier_type === 'Sole Proprietorship' && paymentRequest.transaction_type === 'With 2307'">
                <label for="proprietor_first_name">Proprietor First Name</label>
                <b-input
                  id="proprietor_first_name"
                  v-model="paymentRequest.proprietor_first_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.supplier_type === 'Sole Proprietorship' && paymentRequest.transaction_type === 'With 2307'">
                <label for="proprietor_middle_name">Proprietor Middle Name</label>
                <b-input
                  id="proprietor_middle_name"
                  v-model="paymentRequest.proprietor_middle_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.supplier_type === 'Sole Proprietorship' && paymentRequest.transaction_type === 'With 2307'">
                <label for="proprietor_last_name">Proprietor Last Name</label>
                <b-input
                  id="proprietor_last_name"
                  v-model="paymentRequest.proprietor_last_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.supplier_type !== null && paymentRequest.transaction_type === 'With 2307'">
                <label for="tin">Supplier Tin</label>
                <b-input
                  id="tin"
                  v-model="paymentRequest.tin"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.supplier_type !== null && paymentRequest.transaction_type === 'With 2307'">
                <label for="address">Supplier Address</label>
                <b-textarea
                  id="address"
                  v-model="paymentRequest.address"
                  rows="6"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.tax_accountant_name !== null">
                <label for="tax_accountant_name">Processed By</label>
                <b-input
                  id="tax_accountant_name"
                  :value="paymentRequest.tax_accountant_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.tax_accountant_name !== null">
                <label for="approval_three_at">Processed At</label>
                <b-input
                  id="approval_three_at"
                  :value="paymentRequest.approval_three_at"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="approval_three_remarks">Processed By Remarks</label>
                <b-textarea
                  id="approval_three_remarks"
                  :value="paymentRequest.approval_three_remarks"
                  rows="6"
                  max-rows="6"
                  :disabled="true"
                />
              </b-form-group>

            </b-tab>

            <b-tab
              title="Attachments"
            >
              <b-button
                v-if="paymentRequest.file_list.length>0"
                size="sm"
                variant="success"
                class="mb-2 mr-1"
                @click="onPreviewAllAttachment"
              >
                Preview All
              </b-button>

              <b-table
                class="mt-1"
                :fields="attachmentTableSettings.fields"
                :items="paymentRequest.file_list"
                responsive
                striped
                hover
              >
                <template #cell(actions)="row">
                  <div class="text-nowrap">
                    <b-button
                      size="sm"
                      class="mr-1"
                      :disabled="isDownloadingAttachment"
                      @click="onPreviewAttachment(row.item)"
                    >
                      Preview
                    </b-button>
                    <b-button
                      size="sm"
                      :disabled="isDownloadingAttachment"
                      @click="onDownloadAttachment(row.item)"
                    >
                      Download
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-tab>

            <b-tab
              title="Documents"
            >
              <b-button
                variant="outline-secondary"
                block
                @click="onPreviewDocument"
              >
                Preview Document
              </b-button>

              <b-button
                variant="outline-secondary"
                :disabled="paymentRequest.transaction_type === 'Without 2307'"
                block
                @click="onPreview2307"
              >
                Preview 2307
              </b-button>
            </b-tab>
          </b-tabs>
        </form>
      </b-card>

      <template #modal-footer="{cancel}">
        <b-button
          variant="success"
          @click="onConfirmTagging"
        >
          Completed
        </b-button>

        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-preview-pdf"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachment [${previewFilename}]`"
      size="lg"
    >
      <vue-pdf-embed :source="preview" />

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-preview-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachment [${previewFilename}]`"
      size="lg"
    >
      <div id="printMe1">
        <b-img
          fluid
          :src="preview"
          style="height: 100vh;"
        />
      </div>

      <template #modal-footer="{cancel}">
        <b-button
          v-print="'#printMe1'"
          variant="success"
        >
          Print Attachment
        </b-button>

        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-preview-document"
      scrollable
      no-close-on-backdrop
      title="Preview Document"
      size="lg"
    >
      <div id="printMe2">
        <b-row
          class="print-header"
          :style="cssProps"
        >
          <b-col
            cols="12"
            class="text-center"
          >
            <span class="company">ALJAY AGRO-INDUSTRIAL SOLUTIONS INCORPORATED</span>
          </b-col>
          <b-col
            cols="12"
            class="text-center"
          >
            <span class="title"><strong>REQUEST FOR PAYMENT</strong></span>
          </b-col>
        </b-row>

        <hr>

        <b-row
          class="print-body"
          style="line-height:1.2;"
        >
          <b-col cols="6">
            <b-row>
              <b-col cols="12">
                <strong>Payee:</strong> <span style="text-decoration: underline;">{{ paymentRequest.request_payee }}</span>
              </b-col>
              <b-col cols="12">
                <strong>Amount:</strong>  <span style="text-decoration: underline;">P{{ paymentRequest.request_amount }}</span>
              </b-col>
            </b-row>

          </b-col>
          <b-col cols="6">
            <b-row>
              <b-col cols="12">
                <strong>Date:</strong> <span style="text-decoration: underline;">{{ paymentRequest.created_at }}</span>
              </b-col>
              <b-col cols="12">
                <strong>Doc Number:</strong> <span style="text-decoration: underline;">{{ paymentRequest.document_number }}</span>
              </b-col>
              <b-col cols="12">
                <strong>Transaction Type:</strong> <span style="text-decoration: underline;">{{ paymentRequest.transaction_type }}</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <hr>

        <b-row
          class="print-body"
          style="line-height:1.2;"
        >
          <b-col cols="6">
            <b-row>
              <b-col cols="12">
                <strong>BUDGET DETAILS</strong>
              </b-col>
              <b-col cols="12">
                <strong>Budget:</strong> <span style="text-decoration: underline;">{{ paymentRequest.budget_code }}</span>
              </b-col>
              <b-col cols="12">
                <strong>Actual:</strong> <span style="text-decoration: underline;">{{ paymentRequest.budget_actual_name }}</span>
              </b-col>
              <b-col cols="12">
                <strong>Item Description:</strong> <br> <p>&nbsp;&nbsp;&nbsp;&nbsp;{{ paymentRequest.item_name }}</p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <b-col cols="12">
              <strong>TAX DETAILS</strong>
            </b-col>
            <b-col cols="12">
              <strong>Invoice Amount:</strong> <span style="text-decoration: underline;">{{ paymentRequest.invoice_amount }}</span>
            </b-col>
            <b-col cols="12">
              <strong>Taxable Amount:</strong> <span style="text-decoration: underline;">{{ paymentRequest.amount }}</span>
            </b-col>
            <b-col cols="12">
              <strong>Tax Withheld:</strong> <span style="text-decoration: underline;">{{ paymentRequest.tax_with_held }}</span>
            </b-col>
            <b-col cols="12">
              <strong>Amount to be paid:</strong> <span style="text-decoration: underline;">{{ paymentRequest.pay_amount }}</span>
            </b-col>
          </b-col>
        </b-row>

        <hr>

        <b-row
          class="print-body"
          style="line-height:1.2;"
        >
          <b-col cols="6">
            <b-row>
              <b-col cols="12">
                <strong>Description:</strong> <br> <span style="white-space: pre-line">{{ paymentRequest.request_description.toUpperCase() }}</span>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <b-row>
              <b-col cols="12">
                <strong>Mode of Payment:</strong> <span style="text-decoration: underline;">{{ paymentRequest.payment_mode }}</span>
              </b-col>
              <b-col cols="12">
                <strong>If For Deposit</strong>
              </b-col>
              <b-col cols="12">
                &nbsp;&nbsp;<strong>Account Number:</strong> <span style="text-decoration: underline;">{{ paymentRequest.bank_account_number }}</span>
              </b-col>
              <b-col cols="12">
                &nbsp;&nbsp;<strong>Account Name:</strong> <span style="text-decoration: underline;">{{ paymentRequest.bank_account_name }}</span>
              </b-col>
              <b-col cols="12">
                &nbsp;&nbsp;<strong>Bank:</strong> <span style="text-decoration: underline;">{{ paymentRequest.bank }}</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <hr>

        <b-row
          class="print-body"
          style="line-height:1.2;"
        >
          <b-col cols="12">
            <strong>REQUESTED BY:</strong> {{ `${paymentRequest.user_name} [${paymentRequest.request_date}]` }}
          </b-col>
          <b-col
            v-if="paymentRequest.approval_one_name !== null"
            cols="12"
          >
            <strong>NOTED BY:</strong> {{ `${paymentRequest.approval_one_name} [${paymentRequest.approval_one_at}]` }}
          </b-col>
          <b-col cols="12">
            <strong>APPROVED BY:</strong> {{ `${paymentRequest.approval_two_name} [${paymentRequest.approval_two_at}]` }}
          </b-col>
        </b-row>

        <hr>

        <span>This is a computer-generated document. No signature is required.</span>
      </div>

      <template #modal-footer="{cancel}">
        <b-button
          v-print="'#printMe2'"
          variant="success"
        >
          Print Document
        </b-button>

        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-preview-2307"
      scrollable
      no-close-on-backdrop
      title="Preview 2307"
      size="lg"
    >
      <div
        id="printMe3"
      >
        <div class="container-2307">
          <img
            :src="form2307"
            style="width: 100%;"
          >

          <span class="period-from-one">
            {{ periodFrom ? periodFrom[1] : '' }}
          </span>

          <span class="period-from-two">
            {{ periodFrom ? periodFrom[2] : '' }}
          </span>

          <span class="period-from-three">
            {{ periodFrom ? periodFrom[0] : '' }}
          </span>

          <span class="period-to-one">
            {{ periodTo ? periodTo[1] : '' }}
          </span>

          <span class="period-to-two">
            {{ periodTo ? periodTo[2] : '' }}
          </span>

          <span class="period-to-three">
            {{ periodTo ? periodTo[0] : '' }}
          </span>

          <span class="payee-tin-one">
            {{ tin ? tin[0] : '' }}
          </span>

          <span class="payee-tin-two">
            {{ tin ? tin[1] : '' }}
          </span>

          <span class="payee-tin-three">
            {{ tin ? tin[2] : '' }}
          </span>

          <span class="payee-tin-four">
            {{ tin ? tin[3] : '' }}
          </span>

          <span class="payee-name">
            {{ payeeName }}
          </span>

          <span class="payee-address">
            {{ paymentRequest.address }}
          </span>

          <div class="atc-description">
            {{ paymentRequest.atc_description }}
          </div>

          <span class="atc-code">
            {{ paymentRequest.atc }}
          </span>

          <span class="atc-payment-1st">
            {{ paymentRequest.atc_payment === '1st Month' ? (parseFloat(paymentRequest.amount) === 0 ? '' : paymentRequest.amount ) : '' }}
          </span>

          <span class="atc-payment-2nd">
            {{ paymentRequest.atc_payment === '2nd Month' ? (parseFloat(paymentRequest.amount) === 0 ? '' : paymentRequest.amount ): '' }}
          </span>

          <span class="atc-payment-3rd">
            {{ paymentRequest.atc_payment === '3rd Month' ? (parseFloat(paymentRequest.amount) === 0 ? '' : paymentRequest.amount ): '' }}
          </span>

          <span class="atc-payment-total">
            {{ parseFloat(paymentRequest.amount) === 0 ? '' : paymentRequest.amount }}
          </span>

          <span class="atc-payment-total-down">
            {{ parseFloat(paymentRequest.amount) === 0 ? '' : paymentRequest.amount }}
          </span>

          <span class="atc-payment-tax">
            {{ parseFloat(paymentRequest.tax_with_held) === 0 ? '' : paymentRequest.tax_with_held }}
          </span>

          <span class="atc-payment-tax-total">
            {{ parseFloat(paymentRequest.tax_with_held) === 0 ? '' : paymentRequest.tax_with_held }}
          </span>

          <span class="payee-name-signature">
            {{ payeeName }}
          </span>

          <span class="document-series">
            {{ paymentRequest.document_series }}
          </span>

          <span class="document-number">
            {{ paymentRequest.document_number }}
          </span>
        </div>
      </div>

      <template #modal-footer="{cancel}">
        <b-button
          v-print="'#printMe3'"
          :disabled="isDownloading"
          variant="success"
        >
          Print Document
        </b-button>

        <b-button
          :disabled="isDownloading"
          @click="onDownload2307"
        >
          Download Document
        </b-button>

        <b-button
          :disabled="isDownloading"
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-preview-all-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachments`"
      size="lg"
    >
      <div id="printMe4">
        <table>
          <tr
            v-for="attachment in allAttachments"
            :key="attachment"
          >
            <td
              class="p-0 m-0"
              style="page-break-after: always"
            >
              <b-img
                fluid
                :src="attachment"
                style="height: 100vh;"
              />
            </td>
          </tr>

        </table>
      </div>

      <template #modal-footer="{cancel}">
        <b-button
          v-print="'#printMe4'"
          variant="success"
        >
          Print All
        </b-button>

        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import * as htmlToImage from 'html-to-image'
import { core } from '@/config/pluginInit'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { DisbursementPendingTransactionService, DisbursementActionService, DisbursementAttachmentService } from '@/services'
import print from 'vue-print-nb'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'
import misc from '@/mixins/misc'

export default {
  name: 'DisbursementPendingTransactions',

  middleware: ['auth', 'disbursement'],

  metaInfo () {
    return {
      title: 'Pending Transactions'
    }
  },

  components: {
    VuePdfEmbed
  },

  directives: {
    print
  },

  mixins: [formatter, url, misc],

  data () {
    return {
      isBusy: false,
      isDownloadingAttachment: false,
      isDownloading: false,
      allAttachments: [],
      selectedRow: [],
      isMultipleSelection: false,
      lastForm2307: '',
      form2307: null,
      filter: {
        transaction_type: 'All'
      },
      filterTransactionTypes: [
        { text: 'All', value: 'All' },
        { text: 'With 2307', value: 'With 2307' },
        { text: 'Without 2307', value: 'Without 2307' }
      ],
      selectionModes: [
        { text: 'Single', value: 'single' },
        { text: 'Multiple', value: 'multi' }
      ],
      selectMode: 'single',
      paymentRequest: {
        id: 0,
        created_at: '',
        request_date: '',
        request_payee: '',
        request_amount: 0,
        transaction_type: '',
        request_status: '',
        budget_code: '',
        budget_name: '',
        budget_actual_name: '',
        item_name: '',
        request_description: '',
        payment_mode: '',
        payment_action: '',
        bank: '',
        bank_account_number: '',
        bank_account_name: '',
        user_name: '',
        approval_one_name: '',
        approval_one_at: '',
        approval_one_remarks: '',
        approval_two_name: '',
        approval_two_at: '',
        approval_two_remarks: '',
        approval_three_at: '',
        approval_three_remarks: '',
        tax_accountant_name: '',
        tax_accountant_form: '',
        payment_nature: '',
        document_type: '',
        receipt_type: '',
        period_from: '',
        period_to: '',
        atc: '',
        atc_description: '',
        atc_payment: '',
        document_series: '',
        document_number: '',
        invoice_amount: 0,
        amount: 0,
        tax_with_held: 0,
        pay_amount: 0,
        supplier_type: '',
        trade_name: '',
        company_name: '',
        proprietor_first_name: '',
        proprietor_middle_name: '',
        proprietor_last_name: '',
        tin: '',
        address: '',
        file_path: '',
        file_list: []
      },
      preview: null,
      previewFilename: '',
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'selected',
          { key: 'created_at', formatter: this.dateTimeFormatter, label: 'request date', sortable: true },
          { key: 'request_payee' },
          { key: 'request_status' },
          { key: 'request_amount', formatter: this.numberFormatter, class: 'text-right' },
          { key: 'transaction_type' },
          { key: 'document_number' },
          { key: 'invoice_amount', formatter: this.numberFormatter, class: 'text-right' },
          { key: 'amount', formatter: this.numberFormatter, label: 'taxable amount', class: 'text-right' },
          { key: 'tax_with_held', formatter: this.numberFormatter, label: 'tax withheld', class: 'text-right' },
          { key: 'pay_amount', formatter: this.numberFormatter, class: 'text-right' },
          { key: 'user_name', label: 'requested by' },
          { key: 'approval_one_name', label: 'noted by' },
          { key: 'approval_two_name', label: 'approved by' }
        ]
      },
      attachmentTableSettings: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' },
          { key: 'attachment_type' }
        ]
      },
      cssProps: {
        backgroundImage: `url(${this.getImageUrl(`branding/${this.getCompanyLogo()}`)})`,
        backgroundSize: '15%',
        backgroundRepeat: 'no-repeat'
      },
      hasSelectedRow: false
    }
  },

  computed: {
    payeeName () {
      if (this.paymentRequest.supplier_type === 'Corporation') {
        return this.paymentRequest.trade_name ? this.paymentRequest.trade_name : ''
      }

      const proprietorFirstName = this.paymentRequest.proprietor_first_name ? this.paymentRequest.proprietor_first_name : ''
      const proprietorMiddleName = this.paymentRequest.proprietor_middle_name ? this.paymentRequest.proprietor_middle_name : ''
      const proprietorLastName = this.paymentRequest.proprietor_last_name ? this.paymentRequest.proprietor_last_name : ''

      return `${proprietorFirstName} ${proprietorMiddleName} ${proprietorLastName}`
    },

    tin () {
      const str = this.paymentRequest.tin?.split('-')

      if (str) {
        if (str.length !== 4) {
          return null
        }
      }

      return str
    },

    periodFrom () {
      const str = this.paymentRequest.period_from?.split('-')

      if (str) {
        if (str.length !== 3) {
          return null
        }
      }

      return str
    },

    periodTo () {
      const str = this.paymentRequest.period_to?.split('-')

      if (str) {
        if (str.length !== 3) {
          return null
        }
      }

      return str
    }
  },

  watch: {
    'filter.transaction_type' () {
      this.$refs.table.refresh()
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await DisbursementPendingTransactionService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_type=${this.filter.transaction_type}&filter_text=${ctx.filter}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    onRowSelected (row) {
      this.selectedRow = row
      this.hasSelectedRow = row.length > 0

      if (row.length === 1) {
        this.isMultipleSelection = false
        const item = row[0]
        this.paymentRequest.id = item.id
        this.paymentRequest.created_at = this.dateFormatter(item.created_at)
        this.paymentRequest.request_date = this.dateTimeFormatter(item.created_at)
        this.paymentRequest.request_payee = item.request_payee
        this.paymentRequest.request_amount = this.numberFormatter(item.request_amount)
        this.paymentRequest.transaction_type = item.transaction_type
        this.paymentRequest.request_status = item.request_status
        this.paymentRequest.budget_code = item.budget_code
        this.paymentRequest.budget_name = item.budget_name
        this.paymentRequest.budget_actual_name = item.budget_actual_name
        this.paymentRequest.item_name = item.item_name
        this.paymentRequest.request_description = item.request_description
        this.paymentRequest.payment_mode = item.payment_mode
        this.paymentRequest.payment_action = item.payment_action
        this.paymentRequest.bank = item.bank
        this.paymentRequest.bank_account_number = item.bank_account_number
        this.paymentRequest.bank_account_name = item.bank_account_name
        this.paymentRequest.user_name = item.user_name
        this.paymentRequest.approval_one_name = item.approval_one_name
        this.paymentRequest.approval_one_at = item.approval_one_at
        this.paymentRequest.approval_one_remarks = item.approval_one_remarks
        this.paymentRequest.approval_two_name = item.approval_two_name
        this.paymentRequest.approval_two_at = item.approval_two_at
        this.paymentRequest.approval_two_remarks = item.approval_two_remarks
        this.paymentRequest.approval_three_at = item.approval_three_at
        this.paymentRequest.approval_three_remarks = item.approval_three_remarks
        this.paymentRequest.tax_accountant_name = item.tax_accountant_name
        this.paymentRequest.tax_accountant_form = item.tax_accountant_form
        this.paymentRequest.payment_nature = item.payment_nature
        this.paymentRequest.document_type = item.document_type
        this.paymentRequest.receipt_type = item.receipt_type
        this.paymentRequest.period_from = item.period_from
        this.paymentRequest.period_to = item.period_to
        this.paymentRequest.atc = item.atc
        this.paymentRequest.atc_description = item.atc_description
        this.paymentRequest.atc_payment = item.atc_payment
        this.paymentRequest.document_series = item.document_series
        this.paymentRequest.document_number = item.document_number
        this.paymentRequest.invoice_amount = this.numberFormatter(item.invoice_amount)
        this.paymentRequest.amount = this.numberFormatter(item.amount)
        this.paymentRequest.tax_with_held = this.numberFormatter(item.tax_with_held)
        this.paymentRequest.pay_amount = this.numberFormatter(item.pay_amount)
        this.paymentRequest.supplier_type = item.supplier_type
        this.paymentRequest.trade_name = item.trade_name
        this.paymentRequest.company_name = item.company_name
        this.paymentRequest.proprietor_first_name = item.proprietor_first_name
        this.paymentRequest.proprietor_middle_name = item.proprietor_middle_name
        this.paymentRequest.proprietor_last_name = item.proprietor_last_name
        this.paymentRequest.tin = item.tin
        this.paymentRequest.address = item.address
        this.paymentRequest.file_path = item.file_path
        this.paymentRequest.file_list = item.attachments
      } else {
        this.isMultipleSelection = true
      }
    },

    onShowViewForm () {
      if (this.hasSelectedRow) {
        this.$bvModal.show('modal-view-form')
      }
    },

    onPreviewDocument () {
      this.$bvModal.show('modal-preview-document')
    },

    onConfirmTagging () {
      if (this.hasSelectedRow) {
        this.$swal.fire({
          icon: 'question',
          title: 'Do you really want to tag this transaction as completed?',
          confirmButtonColor: '#06C270',
          confirmButtonText: 'Yes',
          cancelButtonColor: '#FF2929',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return this.onPosTagSingleAsCompleted()
          },
          allowOutsideClick: () => !this.$swal.isLoading()
        })
      }
    },

    onConfirmTaggingAll () {
      if (this.hasSelectedRow) {
        this.$swal.fire({
          icon: 'question',
          title: 'Do you really want to tag all selected transaction as completed?',
          confirmButtonColor: '#06C270',
          confirmButtonText: 'Yes',
          cancelButtonColor: '#FF2929',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return this.onPostTagMultipleAsCompleted()
          },
          allowOutsideClick: () => !this.$swal.isLoading()
        })
      }
    },

    async onPosTagSingleAsCompleted () {
      return new Promise((resolve, reject) => {
        this.isBusy = true
        DisbursementActionService.posTagSingleAsCompleted({ id: this.paymentRequest.id }).then(response => {
          this.isBusy = false
          this.$bvModal.hide('modal-view-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: response.data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(() => {
          this.isBusy = false
        })
      })
    },

    async onPostTagMultipleAsCompleted () {
      return new Promise((resolve, reject) => {
        this.isBusy = true
        const all = []

        this.selectedRow.forEach(row => {
          all.push(row.id)
        })

        DisbursementActionService.postTagMultipleAsCompleted({ selected_ids: all }).then(response => {
          this.isBusy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: response.data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(() => {
          this.isBusy = false
        })
      })
    },

    async onPreview2307 () {
      if (this.lastForm2307 !== this.paymentRequest.tax_accountant_form) {
        this.lastForm2307 = this.paymentRequest.tax_accountant_form
        await DisbursementAttachmentService.retrieveForm(this.paymentRequest.tax_accountant_form)
          .then(({ data }) => {
            this.form2307 = `data:image/jpg;base64,${data}`
            this.$bvModal.show('modal-preview-2307')
          })
      } else {
        this.$bvModal.show('modal-preview-2307')
      }
    },

    onDownload2307 () {
      if (this.hasSelectedRow) {
        this.isDownloading = true
        htmlToImage.toJpeg(document.getElementById('printMe3'), { quality: 0.95 })
          .then(dataUrl => {
            var link = document.createElement('a')
            link.download = `rfp-${this.paymentRequest.document_number}.jpeg`
            link.href = dataUrl
            link.click()
            this.isDownloading = false
          }).catch(() => {
            this.isDownloading = false
          })
      }
    },

    async onPreviewAttachment (item) {
      this.previewFilename = item.file_original_name
      this.preview = this.getImageUrl(`${this.paymentRequest.file_path}/${item.file_hash_name}`)
      this.$bvModal.show('modal-preview-image')
    },

    onPreviewAllAttachment () {
      this.allAttachments = []

      this.paymentRequest.file_list.forEach(file => {
        this.allAttachments.push(this.getImageUrl(`${this.paymentRequest.file_path}/${file.file_hash_name}`))
      })

      this.$bvModal.show('modal-preview-all-image')
    },

    async onDownloadAttachment (item) {
      this.isDownloadingAttachment = true
      await DisbursementAttachmentService.retrieve(this.paymentRequest.id, item.file_hash_name).then(response => {
        const buffer = Buffer.from(response.data, 'base64')
        var fileURL = window.URL.createObjectURL(new Blob([buffer]))
        var fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', item.file_original_name.toLowerCase())
        document.body.appendChild(fileLink)

        fileLink.click()
        this.isDownloadingAttachment = false
      }).catch(() => {
        this.isDownloadingAttachment = false
      })
    },

    clearSelected () {
      this.$refs.table.clearSelected()
    }
  }
}
</script>
